import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import imageBG from "../../assets/academy/frame.png";

const AcademyHero = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="bg-black relative">
      <div className="spacing text-white flex flex-col lg:flex-row p-4 lg:space-x-4 relative z-10">
        {/* Left Section */}
        <div className="flex-none w-full lg:w-1/2 flex flex-col items-start justify-center p-8 relative z-20 bg-cover bg-left">
          <h1 className="text-5xl lg:text-6xl xl:text-8xl font-bold mb-4 leading-tight">
            WINTER REGISTRATION <br /> OPEN
          </h1>
          <h1 className="text-2xl lg:text-2xl xl:text-xl font-bold mb-4 leading-tight">
            SMALL GROUP & INDIVIDUAL SESSIONS <br />
          </h1>
          <p className="text-lg mb-8">
            At P90 Futbol Academy, we pride ourselves on offering exceptional
            training sessions that empower athletes to reach their full
            potential. We provide unlimited individual training sessions,
            allowing players to hone their skills as often as they desire.
            Alongside this, we still offer weekly small group sessions. Each
            athlete also receives a monthly Zoom call to check in on their
            progress, ensuring personalized support and guidance. Players have
            the flexibility to book sessions whenever it suits them, while being
            guaranteed one dedicated spot. This structure not only maximizes
            training opportunities but offers incredible value for our athletes.
            Our mission is to nurture and develop P90 athletes in a holistic
            manner, benefiting them both on and off the pitch.
          </p>
          <div className="flex space-x-4">
            <button
              onClick={() => navigateTo("/membership")}
              className="bg-white text-black font-semibold py-3 px-6 flex items-center justify-center hover:bg-gray-200 transition duration-300 relative group"
            >
              REGISTER
              <FaArrowRight className="ml-2 transition-transform duration-300 group-hover:translate-x-1" />
            </button>
          </div>
          <div className="mt-8 flex space-x-4">
            <div className="bg-pink-500 text-black rounded-full px-3 py-1 text-xs font-semibold">
              TRAINING
            </div>
            <div className="bg-green-500 text-black rounded-full px-3 py-1 text-xs font-semibold">
              MENTORSHIP
            </div>
            <div className="bg-purple-500 text-black rounded-full px-3 py-1 text-xs font-semibold">
              PERSONABILITY
            </div>
          </div>
        </div>
        {/* Right Section */}
        <div className="flex-1 w-full lg:w-1/2 flex items-center justify-center p-8 relative z-20 lg:ml-4 lg:mt-0 mt-8">
          <img
            src={imageBG}
            alt="Training Session"
            className="w-full h-auto"
            style={{ maxWidth: "800px", maxHeight: "1400px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AcademyHero;
