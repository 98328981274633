import React from "react";
import "./p90dna.css";

const P90Dna = () => {
  return (
    <div className="bg-black">
      <div className="spacing">
        <h2 className="section-title">THE P90 DNA</h2>
        <div className="section-content">
          <div className="column">
            <h3 className="column-title">TRAINING</h3>
            <p className="column-text">
              Focusing on enhancing technical ability, speed and agility on and
              off the ball, technical and tactical awareness and how these
              skills are transferred to an in-game environment. The dynamic
              training regimen ensures that athletes are well-rounded and
              equipped to excel in their personal football environment,
              fostering both skill development and physical preparation.
            </p>
          </div>
          <div className="column">
            <h3 className="column-title">MENTORSHIP</h3>
            <p className="column-text">
              Emphasizing the importance of guiding the next generation both on
              and off the pitch. Learn valuable information from those who used
              to be in your shoes through our monthly zoom calls & report cards,
              podcasts and long form youtube content. Listen to endless stories,
              advice, lessons and more from current university and semi pro
              players - all completely free.
            </p>
          </div>
          <div className="column">
            <h3 className="column-title">PERSONABILITY</h3>
            <p className="column-text">
              Being open to learning and feedback is key to personal and
              professional growth. It’s important to speak up for yourself, but
              also know how to take constructive criticism. This balance helps
              you improve, build strong relationships, and adapt to challenges.
              By embracing this mindset, you position yourself for continued
              success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default P90Dna;
