import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import imageBG from "../../assets/home/middle.png";

const TrainingBanner = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate("/membership");
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-black relative">
      <div className="spacing text-white flex flex-col lg:flex-row p-4 lg:space-x-4 relative z-10">
        {/* Left Section */}
        <div
          className="flex-none w-full lg:w-3/5 flex flex-col items-start justify-center p-8 relative z-20 bg-cover bg-left"
          style={{ backgroundImage: `url(${imageBG})` }}
        >
          <div className="bg-white text-black rounded-full px-3 py-1 mb-4 text-sm font-semibold">
            NEW!
          </div>
          <div className="text-white mb-4 text-sm font-semibold">
            SMALL GROUP TRAINING SESSIONS &
          </div>
          <h1 className="text-5xl lg:text-6xl xl:text-8xl font-bold mb-4 leading-tight">
            UNLIMITED <br /> INDIVIDUAL <br /> SESSIONS
          </h1>
          <p className="text-lg mb-8" style={{ maxWidth: "300px" }}>
            With our monthly memberships, players are booked into 1 individual
            session per week, any other open training slots are available for
            booking for 1 week in advance. Small groups operate as normal.
          </p>
          <button
            onClick={handleRegisterClick}
            className="bg-white text-black font-semibold py-3 px-6 flex items-center justify-center hover:bg-gray-200 transition duration-300 relative group"
          >
            REGISTER NOW
            <FaArrowRight className="ml-2 transition-transform duration-300 group-hover:translate-x-1" />
          </button>
        </div>
        {/* Right Section */}
        <div className="flex-1 w-full lg:w-2/5 flex flex-col items-start justify-center p-8 relative z-20 lg:ml-4 lg:mt-0 mt-8">
          <div className="text-sm mb-8 font-bold">INFORMATION</div>
          {/* Questions */}
          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(0)}
            >
              <div className="text-left text-lg">
                What does a normal P90 session look like?
              </div>
              <FontAwesomeIcon icon={openIndex === 0 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 0 && (
              <div className="text-xs mt-2 text-gray-400">
                Every P90 session is a high-quality, energetic training environment specifically designed to improve our players’ in-game performances. Sessions vary for individual, partner, and small group training, but generally include mastering ball techniques, advanced dribbling, passing principles, combinations, and finishing to score.
              </div>
            )}
            <hr className="mt-2" />
          </div>

          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(1)}
            >
              <div className="text-left text-lg">
                Where are your training facilities located?
              </div>
              <FontAwesomeIcon icon={openIndex === 1 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 1 && (
              <div className="text-xs mt-2 text-gray-400">
                Our winter semester training locations include:<br></br>
                <br /><strong>Catherine Nichols Gunn School</strong> (Mon, Tue, Wed, Fri) - <br></br>6625 4 St NE, Calgary, AB T2K 5C7<br></br>
                <br /><strong>Cedarbrae School</strong> (Sunday, 6:30-9:30 PM) - <br></br>10631 Oakfield Dr SW, Calgary, AB T2W 2T3<br></br>
                <br /><strong>Ecole de la Source</strong> (Thursdays, 6:30-9:30 PM) - <br></br>360 94 Ave SE, Calgary, AB T2J 0E8<br></br>
                <br></br>
                <br />All sessions run from 6:30 to 9:30 PM. Locations may vary occasionally.
              </div>
            )}
            <hr className="mt-2" />
          </div>

          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(2)}
            >
              <div className="text-left text-lg">
                How do we track and measure player improvement?
              </div>
              <FontAwesomeIcon icon={openIndex === 2 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 2 && (
              <div className="text-xs mt-2 text-gray-400">
                We build connections. P90 Coaches have regular calls with players to discuss their progress, fostering strong relationships and helping players achieve their personalized goals.
              </div>
            )}
            <hr className="mt-2" />
          </div>

          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(3)}
            >
              <div className="text-left text-lg">What age groups do we coach?</div>
              <FontAwesomeIcon icon={openIndex === 3 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 3 && (
              <div className="text-xs mt-2 text-gray-400">
                At P90, we coach players ages 6-18+ across our various programs, including individual, partner, and small group training. Our grassroots and recreational programs are expanding to accommodate players of all backgrounds.
              </div>
            )}
            <hr className="mt-2" />
          </div>

          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(4)}
            >
              <div className="text-left text-lg">Who are the coaches at P90?</div>
              <FontAwesomeIcon icon={openIndex === 4 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 4 && (
              <div className="text-xs mt-2 text-gray-400">
                Our highly qualified coaches are professionals, semi-professionals, and skilled athletes dedicated to youth soccer development. Led by our founder, Matt Monaghan, they bring passion and expertise to every session.
              </div>
            )}
            <hr className="mt-2" />
          </div>

          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(5)}
            >
              <div className="text-left text-lg">Can I train with my friends at P90?</div>
              <FontAwesomeIcon icon={openIndex === 5 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 5 && (
              <div className="text-xs mt-2 text-gray-400">
                Absolutely! You’re encouraged to bring friends (up to 8 for a small group) or a partner for sessions.
              </div>
            )}
            <hr className="mt-2" />
          </div>

          <div className="mb-6 w-full">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(6)}
            >
              <div className="text-left text-lg">Does P90 have a men's team?</div>
              <FontAwesomeIcon icon={openIndex === 6 ? faArrowDown : faArrowRight} />
            </div>
            {openIndex === 6 && (
              <div className="text-xs mt-2 text-gray-400">
                Yes, P90 Vanguard & Northstar competes in CUSA. Come watch us play at our next event!
              </div>
            )}
            <hr className="mt-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingBanner;
