import React, { useEffect, useRef, useState } from 'react';
import './video.css';
import poster from '../../assets/home/poster.png';

const Video = () => {
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    // Ensure the video plays on load
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Video playback failed:", error);
      });
    }
  }, []);

  const handleLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="video-container">
      {!isVideoLoaded && (
        <img
          src={poster}
          alt="Loading video..."
          className="poster-image"
        />
      )}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className={`background-video ${isVideoLoaded ? 'opacity-100' : 'opacity-0'}`}
        onLoadedData={handleLoadedData}
        controls={false}
        preload="metadata"
      >
        <source src="https://www.dropbox.com/scl/fi/9zh4g0b6ynfvavjyzszpk/P90-Futbol-_-Get-1-Better-Everyday.mp4?rlkey=yibocdvmkdlp8q078z1lfg8g6&st=tpdeirkj&dl=0&raw=1" 
        type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
