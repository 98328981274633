import React, { useState } from "react";
import axios from "axios";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import BannerHeader from "../components/universal/bannerHeader";
import contactBanner from "../assets/contact/contact-banner.png";
import AvailabilityCalendar from "../components/register/AvailabilityCalendar";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    playerAge: "",
    parentName: "",
    parentPhone: "",
    parentEmail: "",
    currentClub: "",
    skillLevel: "",
    clothingSize: "",
    specificPlayers: "",
    availability: {},
    otherRegistrationType: "",
  });

  const [registrationType, setRegistrationType] = useState("INDIVIDUAL");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAvailabilityChange = (availability) => {
    setFormData((prev) => ({ ...prev, availability }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (validateForm()) {
      // Create an availability string
      const availabilityString =
        formData.availability && formData.availability.length > 0
          ? formData.availability
              .map(({ day, time }) => `${day}: ${time}`)
              .join(", ")
          : "No availability selected";

      const message = Object.keys(formData)
        .map((key) => {
          const value =
            key === "availability" ? availabilityString : formData[key];
          return `<strong>${key}:</strong> ${value}<br/>`;
        })
        .join("");
      axios
        .post("https://p90-studdybackends-projects.vercel.app/api/mail", {
          email: "admin@p90futbol.com",
          subject:
            registrationType === "INDIVIDUAL"
              ? "[P90 INDIVIDUAL SESSION REGISTRATION]"
              : registrationType === "SMALL"
              ? "[P90 SMALL GROUP SESSION REGISTRATION]"
              : "[P90 OTHER REGISTRATION]",
          message,
        })

        .then(() => {
          setIsSubmitted(true);
          setIsSubmitting(false);
        })
        .catch(() => {
          setError("Something Went Wrong On Our End! Sorry!");
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    // Basic validation logic
    for (const key in formData) {
      if (
        !formData[key] &&
        key !== "specificPlayers" &&
        key !== "availability" &&
        key !== "otherRegistrationType"
      ) {
        setError(`Please fill out the ${key}.`);
        return false;
      }
    }
    return true;
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        minHeight: "100vh",
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Banner />
      <Navbar currentMenu={0} />
      <BannerHeader text="Your Details" background={contactBanner} />
      <br></br>
      <form
        onSubmit={handleSubmit}
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          margin: "0 auto",
          maxWidth: "600px",
          color: "black",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        {/* Registration Type Selection */}
        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Registration Type:</strong>
          <select
            value={registrationType}
            onChange={(e) => setRegistrationType(e.target.value)}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          >
            <option value="INDIVIDUAL">Individual Session</option>
            <option value="SMALL">Small Group Session</option>
            <option value="OTHER">Other Registration</option>
          </select>
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Player Name:</strong>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          />
        </label>

        {registrationType === "OTHER" && (
          <label style={{ display: "block", marginBottom: "10px" }}>
            <strong>Registration Type:</strong>{" "}
            {/* Label as "Registration Type" */}
            <input
              type="text"
              name="otherRegistrationType"
              value={formData.otherRegistrationType || ""}
              onChange={handleInputChange}
              style={{
                color: "black",
                backgroundColor: "white",
                border: "1px solid #ccc",
                padding: "8px",
                marginBottom: "12px",
                width: "100%",
              }}
            />
          </label>
        )}

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Player Gender</strong>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Player Age:</strong>
          <input
            type="number"
            name="playerAge"
            value={formData.playerAge}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          />
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Parent/Guardian Name:</strong>
          <input
            type="text"
            name="parentName"
            value={formData.parentName}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          />
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Parent/Guardian Phone:</strong>
          <input
            type="tel"
            name="parentPhone"
            value={formData.parentPhone}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          />
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Parent/Guard Email:</strong>
          <input
            type="email"
            name="parentEmail"
            value={formData.parentEmail}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          />
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Current Club:</strong>
          <input
            type="text"
            name="currentClub"
            value={formData.currentClub}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          />
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Skill Level:</strong>
          <select
            name="skillLevel"
            value={formData.skillLevel}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          >
            <option value="">Select</option>
            <option value="University/APDL">University/APDL</option>
            <option value="Majors/Prem">Majors/Prem</option>
            <option value="Tier 1-2">Tier 1-2</option>
            <option value="Tier 3-5">Tier 3-5</option>
            <option value="Not Listed">Not Listed</option>
            <option value="Unsure">Unsure</option>
          </select>
        </label>

        <label style={{ display: "block", marginBottom: "10px" }}>
          <strong>Clothing Sizing:</strong>
          <select
            name="clothingSize"
            value={formData.clothingSize}
            onChange={handleInputChange}
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #ccc",
              padding: "8px",
              marginBottom: "12px",
              width: "100%",
            }}
          >
            <option value="">Select</option>
            <option value="Adult XL">Adult XL</option>
            <option value="Adult L">Adult L</option>
            <option value="Adult M">Adult M</option>
            <option value="Adult S">Adult S</option>
            <option value="Youth XL">Youth XL</option>
            <option value="Youth L">Youth L</option>
            <option value="Youth M">Youth M</option>
            <option value="Youth S">Youth S</option>
          </select>
        </label>

        {registrationType === "SMALL" && (
          <label style={{ display: "block", marginBottom: "10px" }}>
            <strong>Train With Specific Players:</strong>
            <input
              type="text"
              name="specificPlayers"
              value={formData.specificPlayers}
              onChange={handleInputChange}
              style={{
                color: "black",
                backgroundColor: "white",
                border: "1px solid #ccc",
                padding: "8px",
                marginBottom: "12px",
                width: "100%",
              }}
              placeholder="Enter player names, separated by commas"
            />
          </label>
        )}

        <p style={{ marginBottom: "20px" }}>
          <strong>
            {registrationType === "SMALL"
              ? "Please select time slots that DONT work for your small group session."
              : "Please select time slots that DONT work for your weekly guaranteed individual session."}
          </strong>
        </p>

        {/* Availability Calendar */}
        <AvailabilityCalendar
          onAvailabilityChange={handleAvailabilityChange}
          registrationType={registrationType}
        />

        <button
          type="submit"
          disabled={isSubmitting}
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            padding: "10px 20px",
            border: "none",
            cursor: "pointer",
            marginTop: "20px",
          }}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>

        {error && <p style={{ color: "red" }}>{error}</p>}
        {isSubmitted && (
          <p style={{ color: "green" }}>Registration submitted successfully!</p>
        )}
      </form>

      <Footer />
    </div>
  );
};

export default RegistrationForm;
