import React from "react";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import { useInView } from "react-intersection-observer";
import BannerHeader from "../components/universal/bannerHeader";
import campsBanner from "../assets/camp/camps-banner.png"
import FullWidthImage from "../components/camps/fullWidthImage"
import campImage from "../assets/camp/camp.png"
import DaySchedule from "../components/camps/daySchedule";
import CTA from "../components/camps/cta";

const AnimatedComponent = ({ children, direction }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  let initialTransform;
  switch (direction) {
    case "left":
      initialTransform = "translate-x-8";
      break;
    case "right":
      initialTransform = "-translate-x-8";
      break;
    default:
      initialTransform = "translate-y-8";
      break;
  }

  return (
    <div
      ref={ref}
      className={`transition-transform duration-700 ${
        inView
          ? "transform translate-x-0 translate-y-0 opacity-100"
          : `transform ${initialTransform} opacity-0`
      }`}
    >
      {children}
    </div>
  );
};

const Camps = () => {
  return (
    <div className="bg-black">
      <Banner />
      <Navbar currentMenu={3} />
      <BannerHeader text="CAMPS" background={campsBanner} ></BannerHeader>
      <FullWidthImage src={campImage} alt="P90 Camp Details"></FullWidthImage>
      <DaySchedule></DaySchedule>
      <CTA></CTA>
      <Footer />
    </div>
  );
};

export default Camps;
