import React from "react";
import "./daySchedule.css";

const DaySchedule = () => {
  const schedule = [
    {
      day: "DAY 1",
      title: "Volley's & Whips - Vision & Awareness - 1v1 Situations",
      description: "",
    },
    {
      day: "DAY 2",
      title:
        "Clinical Finishing in and around the box - One touch passing and combinations - Dribbling with purpose",
      description: "",
    },
    {
      day: "DAY 3",
      title: "Implementation & Game Day",
      description: "",
    },
  ];

  return (
    <div className="spacing">
      <div className="day-schedule-container">
        <div className="schedule-details">
          <div className="schedule-section">
            <h2 className="section-title">Camp Structure</h2>
            <p>3 topics per day - 35 minute sessions each</p>
          </div>
          <div className="schedule-section">
            <h2 className="section-title">Age Timings</h2>
            <p>2016 - 2012: 9:00am - 11:00am</p>
            <p>2011s - 2009s: 11:00am - 1:00pm</p>
            <p>2008s - 2006s: 1:00pm - 3:00pm</p>
          </div>
        </div>

        {schedule.map((item, index) => (
          <div key={index} className="day-schedule-item">
            <div className="day-box">
              <p className="day-text">{item.day}</p>
            </div>
            <div className="description-box">
              <h3 className="title">{item.title}</h3>
              <p className="description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DaySchedule;
