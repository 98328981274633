import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPlus,
  faMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

// Configure FontAwesome
config.autoAddCss = false;

// Dash component
const Schedule = () => {
  useEffect(() => {
    // Load the iframe resizer script
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js';
    script.onload = () => {
      // Initialize the iframe resizer
      window.iFrameResize({
        log: false,
        checkOrigin: false,
        tolerance: 10,
        sizeHeight: true,
        heightCalculationMethod: 'lowestElement',
        minHeight: 300,
        scrolling: 'auto',
      }, '#glofox_1');
    };
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-black min-h-screen flex flex-col">
      <Navbar currentMenu={-1} />
      <div className="container mx-auto p-4 text-white flex-grow">
      <div>
          <div
            style={{
              backgroundColor: "#FF0000", // Red background
              color: "#FFFFFF", // White text
              padding: "10px 20px", // Padding for spacing
              textAlign: "center", // Center the text
              fontWeight: "bold", // Bold text
              fontSize: "15px", // Large font size
              borderRadius: "5px", // Slightly rounded corners
              margin: "10px 0", // Margin for spacing from other elements
            }}
          >
            You can only register up to <strong>7 days</strong> in advance for a max of <strong>1 session per day</strong>.{" "}
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <iframe
            id="glofox_1"
            src="https://app.glofox.com/portal/#/branch/66fc5e79664ac97e20076622/classes-week-view?header=classes"
            frameBorder="0"
            width="100%"
            height="0" // Set height to 0 to allow the iframe resizer to adjust it
            scrolling="no"
            style={{ display: 'block' }} // Ensures the iframe displays as a block element
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Schedule;
