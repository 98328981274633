import React from "react";
import { useNavigate } from "react-router-dom";
import "./cta.css";
import ctaImage from "../../assets/camp/cta.png";

const CTA = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate("/membership");
  };

  return (
    <div className="spacing">
      <div className="cta-container">
        <div className="cta-image-container">
          <div className="cta-text-container">
            <h2 className="cta-title">DAY ONE OR ONE DAY?</h2>
            <button className="cta-button" onClick={handleRegisterClick}>
              REGISTER NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
