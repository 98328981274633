import React, { useState } from "react";
import axios from "axios";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import { useInView } from "react-intersection-observer";
import BannerHeader from "../components/universal/bannerHeader";
import contactBanner from "../assets/contact/contact-banner.png";
import AvailabilityCalendar from "../components/register/AvailabilityCalendar";

const AnimatedComponent = ({ children, direction }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  let initialTransform;
  switch (direction) {
    case "left":
      initialTransform = "translate-x-8";
      break;
    case "right":
      initialTransform = "-translate-x-8";
      break;
    default:
      initialTransform = "translate-y-8";
      break;
  }

  return (
    <div
      ref={ref}
      className={`transition-transform duration-700 ${
        inView
          ? "transform translate-x-0 translate-y-0 opacity-100"
          : `transform ${initialTransform} opacity-0`
      }`}
    >
      {children}
    </div>
  );
};

const Success = () => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [availability, setAvailability] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [registrationType, setRegistrationType] = useState("");

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      if (type === "checkbox") {
        // Handle checkboxes separately
        const prevValues = prevData[name] || [];
        if (checked) {
          // Add the checked value to the array
          return {
            ...prevData,
            [name]: [...prevValues, value],
          };
        } else {
          // Remove the unchecked value from the array
          return {
            ...prevData,
            [name]: prevValues.filter((v) => v !== value),
          };
        }
      } else {
        // Handle other input types
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const handleAvailabilityChange = (newAvailability) => {
    setAvailability(newAvailability);
    setFormData((prevData) => ({
      ...prevData,
      availability: newAvailability,
    }));
  };

  const validateForm = () => {
    const requiredFields =
      registrationType === "new"
        ? [
            "name",
            "desiredTrainingFrequency",
            "playerAge",
            "currentClub",
            "playerGender",
            "apparelSize",
            "parentGuardianFullName",
            "parentGuardianEmail",
            "parentGuardianPhone",
            "currentTier",
            "playerExperience",
            "playerGoal",
            "signedUpPrograms",
          ]
        : registrationType === "returning"
        ? [
            "name",
            "parentGuardianFullName",
            "parentGuardianEmail",
            "parentGuardianPhone",
            "availability",
            "signedUpPrograms",
          ]
        : [
            "name",
            "playerAge",
            "parentGuardianEmail",
            "parentGuardianPhone",
            "apparelSize",
          ];

    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (validateForm()) {
      const availabilityString = Object.entries(formData.availability || {})
        .map(([day, time]) => `${day}: ${time}`)
        .join(", ");

      const message = Object.keys(formData)
        .map((key) => {
          const value =
            key === "availability" ? availabilityString : formData[key];
          return `<strong>${key}:</strong> ${value}<br/>`;
        })
        .join("");

      axios
        .post("https://p90-studdybackends-projects.vercel.app/api/mail", {
          email: "admin@p90futbol.com",
          subject:
            registrationType === "new"
              ? "[P90 NEW PLAYER REGISTRATION]"
              : registrationType === "returning"
              ? "[P90 EXISTING PLAYER REGISTRATION]"
              : "[CAMP REGISTRATION]",
          message,
        })
        .then((response) => {
          setIsSubmitted(true);
          setIsSubmitting(false);
        })
        .catch((error) => {
          setError("Something Went Wrong On Our End! Sorry!");
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-black min-h-screen flex flex-col justify-between">
      <div>
        <Banner />
        <Navbar currentMenu={0} />
        <BannerHeader text="PAYMENT SUCCESS" background={contactBanner} />
        <div className="spacing container mx-auto p-4">
          <AnimatedComponent direction="up">
            {isSubmitted ? (
              <div className="text-white text-center">
                <h1 className="text-4xl font-bold mb-4">Thank You!</h1>
                <p className="text-lg">
                  Your inquiry has been submitted successfully.
                </p>
              </div>
            ) : (
              <>
                {!registrationType ? (
                  <>
                    <h1 className="text-4xl font-bold mb-4 text-white text-center">
                      Please Select Your Registration Type
                    </h1>
                    <div className="flex justify-center">
                      <button
                        className="bg-white text-black font-bold p-2 m-2 rounded"
                        onClick={() => setRegistrationType("new")}
                      >
                        New Player
                      </button>
                      <button
                        className="bg-white text-black font-bold p-2 m-2 rounded"
                        onClick={() => setRegistrationType("returning")}
                      >
                        Returning Player
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="text-4xl font-bold mb-4 text-white">
                      {registrationType === "new"
                        ? "PLEASE FILL OUT THE FORM BELOW"
                        : registrationType === "returning"
                        ? "EXISTING PLAYER REGISTRATION"
                        : "CAMP REGISTRATION"}
                    </h1>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <form onSubmit={handleSubmit}>
                      {registrationType === "new" && (
                        <>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Full Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.name && (
                              <p className="text-red-500">{errors.name}</p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Desired Training Frequency *<br />
                              *Per week, put 'one' for single sessions*
                            </label>
                            <input
                              type="text"
                              name="desiredTrainingFrequency"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.desiredTrainingFrequency && (
                              <p className="text-red-500">
                                {errors.desiredTrainingFrequency}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Age *
                            </label>
                            <input
                              type="text"
                              name="playerAge"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.playerAge && (
                              <p className="text-red-500">{errors.playerAge}</p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Current Club *
                            </label>
                            <input
                              type="text"
                              name="currentClub"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.currentClub && (
                              <p className="text-red-500">
                                {errors.currentClub}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Gender *
                            </label>
                            <select
                              name="playerGender"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            >
                              <option>Select Gender</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Other</option>
                            </select>
                            {errors.playerGender && (
                              <p className="text-red-500">
                                {errors.playerGender}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              When are you not available? *<br />
                              Please leave at least 5 spaces open
                            </label>
                            <AvailabilityCalendar
                              onAvailabilityChange={handleAvailabilityChange}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Apparel Size *
                            </label>
                            <select
                              name="apparelSize"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            >
                              <option>Please Select An Option</option>
                              <option>Youth Medium</option>
                              <option>Youth Large</option>
                              <option>Adult Small</option>
                              <option>Adult Medium</option>
                              <option>Adult Large</option>
                            </select>
                            {errors.apparelSize && (
                              <p className="text-red-500">
                                {errors.apparelSize}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Full Name *
                            </label>
                            <input
                              type="text"
                              name="parentGuardianFullName"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianFullName && (
                              <p className="text-red-500">
                                {errors.parentGuardianFullName}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Email *
                            </label>
                            <input
                              type="email"
                              name="parentGuardianEmail"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianEmail && (
                              <p className="text-red-500">
                                {errors.parentGuardianEmail}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Secondary Email
                            </label>
                            <input
                              type="email"
                              name="parentGuardianSecondaryEmail"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Email
                            </label>
                            <input
                              type="email"
                              name="playerEmail"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Phone *
                            </label>
                            <input
                              type="tel"
                              name="parentGuardianPhone"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianPhone && (
                              <p className="text-red-500">
                                {errors.parentGuardianPhone}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Current Tier *
                            </label>
                            <select
                              name="currentTier"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            >
                              <option value="">Select your current tier</option>
                              <option value="Pro">Pro</option>
                              <option value="Semi-Pro">Semi-Pro</option>
                              <option value="University/League1">
                                University/League1
                              </option>
                              <option value="Majors/Prem">Majors/Prem</option>
                              <option value="APDL">APDL</option>
                              <option value="Tier 1">Tier 1</option>
                              <option value="Tier 2">Tier 2</option>
                              <option value="Tier 3">Tier 3</option>
                              <option value="Tier 4">Tier 4</option>
                              <option value="Tier 5">Tier 5</option>
                              <option value="No Current Level">
                                No Current Level
                              </option>
                            </select>
                            {errors.currentTier && (
                              <p className="text-red-500">
                                {errors.currentTier}
                              </p>
                            )}
                          </div>

                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Experience *
                            </label>
                            <input
                              type="text"
                              name="playerExperience"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.playerExperience && (
                              <p className="text-red-500">
                                {errors.playerExperience}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Goal *
                            </label>
                            <input
                              type="text"
                              name="playerGoal"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.playerGoal && (
                              <p className="text-red-500">
                                {errors.playerGoal}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Programs Signed Up For *
                            </label>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Grassroots"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Grassroots
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Individual"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Individual
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Small Group"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Small Group
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Partner"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">Partner</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Foundations"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Foundations
                                </span>
                              </label>
                            </div>
                            {errors.signedUpPrograms && (
                              <p className="text-red-500">
                                {errors.signedUpPrograms}
                              </p>
                            )}
                          </div>

                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Referral Code OR Referring Player Name
                            </label>
                            <input
                              type="text"
                              name="referralCode"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                            />
                          </div>
                          {/* <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Opt-in for Nutritional Program * - $35.00 Per
                              Semester with Certified P90 Nutritionist
                            </label>
                            <select
                              name="optInMentorship"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            >
                              <option>Select Option</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                            {errors.optInMentorship && (
                              <p className="text-red-500">
                                {errors.optInMentorship}
                              </p>
                            )}
                          </div> */}
                        </>
                      )}

                      {registrationType === "returning" && (
                        <>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Full Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.name && (
                              <p className="text-red-500">{errors.name}</p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Full Name *
                            </label>
                            <input
                              type="text"
                              name="parentGuardianFullName"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianFullName && (
                              <p className="text-red-500">
                                {errors.parentGuardianFullName}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Email *
                            </label>
                            <input
                              type="email"
                              name="parentGuardianEmail"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianEmail && (
                              <p className="text-red-500">
                                {errors.parentGuardianEmail}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Phone *
                            </label>
                            <input
                              type="tel"
                              name="parentGuardianPhone"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianPhone && (
                              <p className="text-red-500">
                                {errors.parentGuardianPhone}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              When are you not available? *<br />
                              Please leave at least 5 spaces open
                            </label>
                            <AvailabilityCalendar
                              onAvailabilityChange={handleAvailabilityChange}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Goal *
                            </label>
                            <input
                              type="text"
                              name="playerGoal"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.playerGoal && (
                              <p className="text-red-500">
                                {errors.playerGoal}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Programs Signed Up For *
                            </label>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Grassroots"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Grassroots
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Individual"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Individual
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Small Group"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">
                                  Small Group
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  name="signedUpPrograms"
                                  value="Partner"
                                  onChange={handleFormChange}
                                  className="form-checkbox text-black"
                                />
                                <span className="ml-2 text-white">Partner</span>
                              </label>
                            </div>
                          </div>
                          {/* <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Opt-in for Nutritional Program * - $35.00 Per
                              Semester with Certified P90 Nutritionist
                            </label>
                            <select
                              name="optInMentorship"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            >
                              <option>Select Option</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                            {errors.optInMentorship && (
                              <p className="text-red-500">
                                {errors.optInMentorship}
                              </p>
                            )}
                          </div> */}
                        </>
                      )}

                      {registrationType === "camp" && (
                        <>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Full Name *
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.name && (
                              <p className="text-red-500">{errors.name}</p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Age *
                            </label>
                            <input
                              type="text"
                              name="playerAge"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.playerAge && (
                              <p className="text-red-500">{errors.playerAge}</p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Player Experience *
                            </label>
                            <input
                              type="text"
                              name="playerExperience"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.playerExperience && (
                              <p className="text-red-500">
                                {errors.playerExperience}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Email *
                            </label>
                            <input
                              type="email"
                              name="parentGuardianEmail"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianEmail && (
                              <p className="text-red-500">
                                {errors.parentGuardianEmail}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Parent/Guardian Phone *
                            </label>
                            <input
                              type="tel"
                              name="parentGuardianPhone"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            />
                            {errors.parentGuardianPhone && (
                              <p className="text-red-500">
                                {errors.parentGuardianPhone}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold text-white">
                              Apparel Size *
                            </label>
                            <select
                              name="apparelSize"
                              className="w-full p-2 text-black"
                              onChange={handleFormChange}
                              required
                            >
                              <option>Please Select An Option</option>
                              <option>Youth Medium</option>
                              <option>Youth Large</option>
                              <option>Adult Small</option>
                              <option>Adult Medium</option>
                              <option>Adult Large</option>
                            </select>
                            {errors.apparelSize && (
                              <p className="text-red-500">
                                {errors.apparelSize}
                              </p>
                            )}
                          </div>
                        </>
                      )}

                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="bg-gray-600 text-white font-bold p-2 rounded"
                          onClick={() => setRegistrationType("")}
                        >
                          Return
                        </button>
                        <button
                          type="submit"
                          className="bg-white text-black font-bold p-2 rounded"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </>
            )}
          </AnimatedComponent>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Success;
