import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaTiktok, FaGoogle } from "react-icons/fa";
import logo from "../../assets/home/p90logo.png";

const Footer = () => {
  return (
    <div className="bg-black">
      <footer
        className="spacing text-white py-12 px-8"
        style={{ fontFamily: "Inter, sans-serif" }}
      >
        <div className="border-t border-gray-700 pt-8">
          <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
            <div className="mb-8 md:mb-0 flex flex-col items-center md:items-start text-center md:text-left">
              <img src={logo} alt="Logo" className="h-20 mb-4" />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-6 mb-8 md:mb-0 items-center">
              <Link
                to="/"
                className="font-extrabold hover:underline mb-2 md:mb-0"
              >
                Home
              </Link>
              <Link
                to="/academy"
                className="font-extrabold hover:underline mb-2 md:mb-0"
              >
                Academy
              </Link>
              <Link
                to="/membership"
                className="font-extrabold hover:underline mb-2 md:mb-0"
              >
                Memberships
              </Link>
              <Link
                to="/contact"
                className="font-extrabold hover:underline mb-2 md:mb-0"
              >
                Contact Us
              </Link>
              <Link
                to="/schedule"
                className="font-extrabold hover:underline mb-2 md:mb-0"
              >
                Schedule
              </Link>
            </div>
            <div className="flex justify-center md:justify-end space-x-4">
              <a
                href="https://www.instagram.com/p90futbol/"
                aria-label="Instagram"
                className="hover:text-gray-400"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://www.tiktok.com/@p90futbol"
                aria-label="TikTok"
                className="hover:text-gray-400"
              >
                <FaTiktok size={24} />
              </a>
              <a
                href="https://www.google.com/search?q=p90+futbol&oq=P90+Futbol&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGEAyBggCEEUYPDIGCAMQRRg8MgYIBBBFGDzSAQgxNzM5ajBqMagCALACAA&sourceid=chrome&ie=UTF-8"
                aria-label="Google"
                className="hover:text-gray-400"
              >
                <FaGoogle size={24} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
