import React from "react";
import "./fullWidthImage.css";

const FullWidthImage = ({ src, alt }) => {
  return (
    <div className="spacing full-width-image-container">
      <img src={src} alt={alt} className="full-width-image" />
    </div>
  );
};

export default FullWidthImage;
