import React from "react";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Video from "../components/home/video";
import Lines from "../components/universal/lines";
import TrainingBanner from "../components/home/trainingBanner";
import Stats from "../components/home/stats";
import Packages from "../components/home/packages";
import SocialMediaFollow from "../components/home/socialMediaFollow";
import OurTeam from "../components/home/coach";
import Footer from "../components/universal/footer";
import { useInView } from "react-intersection-observer";
import Testimonials from "../components/home/testimonials";
import Packages2 from "../components/home/packages2";

const AnimatedComponent = ({ children, direction }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  let initialTransform;
  switch (direction) {
    case "left":
      initialTransform = "translate-x-8";
      break;
    case "right":
      initialTransform = "-translate-x-8";
      break;
    default:
      initialTransform = "translate-y-8";
      break;
  }

  return (
    <div
      ref={ref}
      className={`transition-transform duration-700 ${
        inView
          ? "transform translate-x-0 translate-y-0 opacity-100"
          : `transform ${initialTransform} opacity-0`
      }`}
    >
      {children}
    </div>
  );
};

const Home = () => {
  return (
    <div className="bg-black">
      <Banner />
      <Navbar currentMenu={1} />
      <Video />
      <Lines />
      <AnimatedComponent direction="up">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <TrainingBanner />
      </AnimatedComponent>
      <Lines />
      <AnimatedComponent direction="left">
        <SocialMediaFollow />
      </AnimatedComponent>
      <AnimatedComponent direction="right">
        <Testimonials />
      </AnimatedComponent>
      <AnimatedComponent direction="left">
        <OurTeam />
      </AnimatedComponent>
      <Footer />
    </div>
  );
};

export default Home;
