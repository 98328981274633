import React, { useState } from 'react';
import axios from 'axios';
import { FaEnvelope, FaUser, FaRegCommentDots } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    inquiry: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    const { name, email, inquiry } = formData;
    const message = `Name: ${name}\nEmail: ${email}\nInquiry: ${inquiry}`;

    axios.post('https://p90-studdybackends-projects.vercel.app/api/mail', {
      email: 'admin@p90futbol.com',
      subject: '[P90 INQUIRY FOR ADMIN]',
      message
    })
    .then(response => {
      setIsSubmitted(true);
      setIsSubmitting(false);
    })
    .catch(error => {
      setError('Something Went Wrong On Our End! Sorry!');
      setIsSubmitting(false);
    });
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-black">
      <div className="w-full max-w-2xl p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-center text-black text-3xl font-bold mb-6">
          If you have any questions or concerns, feel free to reach out to us!
        </h2>
        {isSubmitted ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center text-2xl text-green-500"
          >
            We'll Be In Touch!
          </motion.div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="form-group">
              <label htmlFor="name" className="block text-lg font-medium text-gray-700">Name</label>
              <div className="relative">
                <FaUser className="absolute left-3 top-3 text-black" />
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 pl-10 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email" className="block text-lg font-medium text-gray-700">Email</label>
              <div className="relative">
                <FaRegCommentDots className="absolute left-3 top-3 text-black" />
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 pl-10 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inquiry" className="block text-lg font-medium text-gray-700">Inquiry Content</label>
              <div className="relative">
                <FaEnvelope className="absolute left-3 top-3 text-black" />
                <textarea
                  id="inquiry"
                  name="inquiry"
                  value={formData.inquiry}
                  onChange={handleChange}
                  className="w-full p-3 pl-10 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-400 text-black"
                  required
                ></textarea>
              </div>
            </div>
            {error && (
              <div className="text-red-500 text-center">
                {error}
              </div>
            )}
            <motion.button
              type="submit"
              className="w-full py-3 bg-black text-white font-medium rounded focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </motion.button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
