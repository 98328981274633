// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Academy from './pages/Academy';
import Camps from './pages/Camps';
import Contact from './pages/Contact';
import Membership from './pages/Membership';
import Success from './pages/Success';
import Shop from './pages/Shop';
import Failure from './pages/Failure';
import TOS from './pages/Tos';
import Schedule from './pages/Schedule';
import Details from './pages/Details';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/camps" element={<Camps />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/details" element={<Details />} />
      </Routes>
    </Router>
  );
};

export default App;
