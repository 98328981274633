import React from "react";
import { useNavigate } from "react-router-dom";
import "./teamTraining.css";
import trainingImage from "../../assets/academy/teamTraining.png";

const TeamTraining = () => {
  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact");
  };

  return (
    <div className="spacing full-team-training">
      <div className="image-container">
        <img src={trainingImage} alt="Training" className="training-image" />
      </div>
      <div className="text-container">
        <div className="text-content">
          <h2 className="title">FULL TEAM TRAINING</h2>
          <h3 className="subtitle">CUSTOM PRICING</h3>
          <p className="description">PER PLAYER PER TEAM</p>
          <button
            onClick={navigateToContact}
            className="contact-button"
          >
            CLICK HERE TO CONTACT
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamTraining;
