import React from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import { useInView } from "react-intersection-observer";

const AnimatedComponent = ({ children, direction }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  let initialTransform;
  switch (direction) {
    case "left":
      initialTransform = "translate-x-8";
      break;
    case "right":
      initialTransform = "-translate-x-8";
      break;
    default:
      initialTransform = "translate-y-8";
      break;
  }

  return (
    <div
      ref={ref}
      className={`transition-transform duration-700 ${
        inView
          ? "transform translate-x-0 translate-y-0 opacity-100"
          : `transform ${initialTransform} opacity-0`
      }`}
    >
      {children}
    </div>
  );
};

const Failure = () => {
  const navigate = useNavigate();

  const handleTryAgain = () => {
    navigate("/membership");
  };

  return (
    <div className="bg-black">
      <Banner />
      <Navbar currentMenu={4} />
      <div className="spacing flex flex-col items-center justify-center min-h-screen text-white">
        <h1 className="text-4xl font-bold mb-4">Payment Failed</h1>
        <p className="text-lg text-center mb-8">Unfortunately, your payment could not be processed! Please Try Again!</p>
        <button
          onClick={handleTryAgain}
          className="bg-white text-black px-6 py-2 rounded-full"
        >
          Try Again
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Failure;
