import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './testimonials.css';

// Testimonials data
const reviews = [
  {
    name: "Parent",
    review: "Matt has done a great job of setting up his academy. My son speaks very highly of not only him, but the entire experience. Keep up the good work!",
    session: "Individual Sessions"
  },
  {
    name: "Parent",
    review: "My husband and I have been very impressed with Plus90 and Matt's coaching! Alex has improved a lot, and we are very pleased that Alex has taken your program during the summer!",
    session: "Small Group Training Sessions"
  },
  {
    name: "Parent",
    review: "First and foremost, I want to commend Matt for his unwavering commitment and his dedication to creating a thriving Plus90. It's evident that his passion for training or even his own club, is contagious, and it truly reflects in the way that he coaches and manages Plus90.",
    session: "Partner Training"
  },
  {
    name: "Parent",
    review: "One of the standout features of Plus90, in my opinion, is its unparalleled uniqueness in comparison to other soccer clubs. The approach Matt takes to coaching and management sets Plus90 apart from the rest.",
    session: "Individual Sessions"
  },
  {
    name: "Parent",
    review: "The emphasis on not only developing soccer skills but also instilling valuable life lessons into our young players is truly remarkable.",
    session: "Small Group Training Sessions"
  },
  {
    name: "Parent",
    review: "Plus90 is more than just a soccer academy; it's a holistic development program that nurtures both the athlete and the individual.",
    session: "Partner Training"
  },
  {
    name: "Parent",
    review: "I believe Plus90 has immense potential in harnessing the capabilities of young players effectively.",
    session: "Individual Sessions"
  },
  {
    name: "Parent",
    review: "The uniqueness of the academy allows young players to benefit quickly from their experience.",
    session: "Small Group Training Sessions"
  },
  {
    name: "Parent",
    review: "The progress parents have witnessed in their children's soccer abilities is a testament to the effectiveness of Matt's coaching methods.",
    session: "Partner Training"
  },
  {
    name: "Parent",
    review: "Matt's profound knowledge and passion for the training of soccer players are palpable and greatly appreciated.",
    session: "Individual Sessions"
  },
  {
    name: "Parent",
    review: "This program has a very professional and dedicated coach who cares about each player's improvement, and also a friendly person, and since my son has started his sessions with Plus90 I have enjoyed watching his eagerness to come to train.",
    session: "Small Group Training Sessions"
  },
  {
    name: "Parent",
    review: "We have enjoyed Matt's coaching technique, the way he gets involved in the drills and his flexibility in scheduling. He takes the time to notice the little things that can be corrected and then explain the impact that the changes will make during a game. Matt has made a genuine connection with Jayce. Nothing but great things to say to you from us!",
    session: "Partner Training"
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 6000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const nextReview = () => {
    setCurrentIndex((currentIndex + 1) % reviews.length);
  };

  const prevReview = () => {
    setCurrentIndex((currentIndex - 1 + reviews.length) % reviews.length);
  };

  return (
    <div className="bg-black py-20">
      <div className="spacing">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-extrabold text-center mb-12 text-white">TESTIMONIALS</h2>
          <div className="flex justify-center items-center mb-10">
            <button onClick={prevReview} className="text-white p-3 rounded-l-lg">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <TransitionGroup className="review-container">
              <CSSTransition
                key={currentIndex}
                timeout={500}
                classNames="slide"
              >
                <div className="review-card">
                  <p className="text-lg font-medium mb-4 text-white">"{reviews[currentIndex].review}"</p>
                  <p className="text-sm font-bold text-white">{reviews[currentIndex].name}</p>
                  <p className="text-sm text-white">{reviews[currentIndex].position}</p>
                </div>
              </CSSTransition>
            </TransitionGroup>
            <button onClick={nextReview} className="text-white p-3 rounded-r-lg">
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
