import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPlus,
  faMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

config.autoAddCss = false;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const colorMapping = {
  Black: "#333333", // Darker grey instead of pure black
  White: "#FFFFFF",
  Beige: "#F5F5DC",
  Green: "#008000",
};

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [cart, setCart] = useState([]);
  const [error, setError] = useState({});
  const [showBanner, setShowBanner] = useState(false);
  const [bannerProduct, setBannerProduct] = useState(null);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/api/products/shop`)
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const handleColorChange = (productId, color) => {
    setSelectedProducts((prevState) => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        color,
      },
    }));
  };

  const handleSizeChange = (productId, size) => {
    setSelectedProducts((prevState) => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        size,
      },
    }));
  };

  const handleQuantityChange = (productId, increment) => {
    setSelectedProducts((prevState) => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        quantity: (prevState[productId]?.quantity || 1) + (increment ? 1 : -1),
      },
    }));
  };

  const addToCart = (product) => {
    const { id } = product;
    const { size, color, quantity } = selectedProducts[id] || {};

    if (!size || !color) {
      setError({ general: "Please select both size and color." });
      setShowErrorBanner(true);
      setTimeout(() => setShowErrorBanner(false), 3000); // Hide banner after 3 seconds
      return;
    }

    const newCart = [
      ...cart,
      { ...product, size, color, quantity: quantity || 1 },
    ];
    setCart(newCart);
    setError({}); // Clear error if added successfully
    setBannerProduct(product);
    setShowBanner(true);

    setTimeout(() => setShowBanner(false), 3000); // Hide banner after 3 seconds
  };

  const removeFromCart = (index) => {
    const newCart = cart.filter((_, i) => i !== index);
    setCart(newCart);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleStripeCheckout = async () => {
    if (!validateForm()) return;

    const stripe = await stripePromise;

    
    // Format cart items and calculate total amounts
    const formattedCart = cart.map((item) => {
      const unitAmount = Math.round(item.price * item.quantity); // Price in cents
      return {
        id: item.id,
        name: `${item.name} | ${item.color} | ${item.size} x ${item.quantity}`,
        price: unitAmount, // Price in cents
        quantity: item.quantity,
        description1: item.description1,
        description2: item.description2,
        image: item.image,
      };
    });

    // Create line items for Stripe
    const lineItems = formattedCart.map((item) => ({
      price_data: {
        currency: "usd",
        product_data: {
          name: item.name,
        },
        unit_amount: item.price,
      },
      quantity: item.quantity,
    }));

    // Concatenate all products into one pseudo product name
    const pseudoProductName = formattedCart
      .map(
        (item) =>
          `${item.name} (${item.size}, ${item.color} x ${item.quantity})`
      )
      .join(", ");

    const dataToSend = {
      selectedProducts: formattedCart,
      formData,
      pseudoProductName,
    };

    console.log(
      "Data being sent to Stripe:",
      JSON.stringify(dataToSend, null, 2)
    ); // Log data for debugging

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/api/payments/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error("Error redirecting to checkout:", result.error.message);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const totalCost = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div className="bg-black min-h-screen flex flex-col">
      <Navbar currentMenu={5} />
      <div className="container mx-auto p-4 text-white flex-grow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-3xl font-bold">Pre-Order Our Collection [3-6 Week Delivery]</h2>
          <div className="relative">
            <FontAwesomeIcon icon={faShoppingCart} className="text-2xl" />
            {cart.length > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                {cart.length}
              </span>
            )}
          </div>
        </div>

        {cart.length > 0 && (
          <div className="mt-4 bg-white text-black p-4">
            <h3 className="text-xl font-bold mb-2">Cart</h3>
            <ul>
              {cart.map((item, index) => (
                <li
                  key={index}
                  className="mb-2 flex items-center justify-between"
                >
                  <span>
                    {item.name} - {item.size} - {item.color} - Qty:{" "}
                    {item.quantity}
                  </span>
                  <button
                    onClick={() => removeFromCart(index)}
                    className="text-red-500"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </li>
              ))}
            </ul>
            <div className="mt-2 text-xl font-bold">
              Total: ${totalCost.toFixed(2)}
            </div>

            <div className="mt-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 mb-2 border rounded"
              />
              {error.name && (
                <div className="text-red-500 mb-2">{error.name}</div>
              )}
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 mb-2 border rounded"
              />
              {error.email && (
                <div className="text-red-500 mb-2">{error.email}</div>
              )}
            </div>

            <button
              onClick={handleStripeCheckout}
              className="bg-black text-white font-bold p-2 rounded mt-2 transition-transform transform hover:scale-105"
            >
              Checkout
            </button>
          </div>
        )}
        {showErrorBanner && (
          <div className="fixed top-0 left-0 w-full bg-red-500 text-white p-2 flex items-center justify-between">
            <div className="flex items-center">
              <span>{error.general}</span>
            </div>
            <button
              onClick={() => setShowErrorBanner(false)}
              className="text-white font-bold"
            >
              &times;
            </button>
          </div>
        )}

        {showBanner && bannerProduct && (
          <div className="fixed top-0 left-0 w-full bg-green-500 text-white p-2 flex items-center justify-between">
            <div className="flex items-center">
              <span>Added to cart: {bannerProduct.name}</span>
            </div>
            <button
              onClick={() => setShowBanner(false)}
              className="text-white font-bold"
            >
              &times;
            </button>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product) => (
            <div
              key={product.id}
              className="text-white p-4 rounded-lg shadow-md"
            >
              <img
                src={
                  product.images[
                    selectedProducts[product.id]?.color || product.colors[0]
                  ]
                }
                alt={product.name}
                className="w-full mb-4"
              />
              <h3 className="text-xl font-bold">{product.name}</h3>
              <p className="mb-2">{product.description}</p>
              <p className="text-lg font-bold">${product.price.toFixed(2)}</p>
              <div className="mt-2">
                <div className="mb-2">
                  <div className="flex space-x-2">
                    {product.colors.map((color) => (
                      <div
                        key={color}
                        onClick={() => handleColorChange(product.id, color)}
                        className={`w-6 h-6 cursor-pointer rounded-full border-2 ${
                          selectedProducts[product.id]?.color === color
                            ? "border-red-500"
                            : "border-transparent"
                        }`}
                        style={{ backgroundColor: colorMapping[color] }}
                      />
                    ))}
                  </div>
                </div>
                {product.sizes.length > 0 && (
                  <div className="mb-2">
                    <select
                      onChange={(e) =>
                        handleSizeChange(product.id, e.target.value)
                      }
                      value={selectedProducts[product.id]?.size || ""}
                      className="w-full p-2 border rounded text-black"
                    >
                      <option value="">Select size</option>
                      {product.sizes.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="flex items-center mb-2">
                  <button
                    onClick={() => handleQuantityChange(product.id, false)}
                    className="p-2 bg-white rounded text-black"
                    disabled={
                      !selectedProducts[product.id]?.quantity ||
                      selectedProducts[product.id]?.quantity <= 1
                    }
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <span className="mx-2">
                    {selectedProducts[product.id]?.quantity || 1}
                  </span>
                  <button
                    onClick={() => handleQuantityChange(product.id, true)}
                    className="p-2 bg-white rounded text-black"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                <br></br>
                <button
                  onClick={() => addToCart(product)}
                  className="w-full bg-white text-black font-bold p-2 rounded"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Shop;
