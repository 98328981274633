import React from "react";
import socials from "../../assets/home/socials.png";

const SocialMediaFollow = () => {
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div className="spacing flex flex-col md:flex-row items-center p-4 bg-black text-white">
        <div className="w-full md:w-2/3">
          <img src={socials} alt="Social Media Preview" className="w-full" />
        </div>
        <div className="w-full md:w-1/3 md:ml-4 mt-4 md:mt-0 flex flex-col justify-center text-center md:text-left">
          <h1 className="text-6xl lg:text-8xl font-bold mb-4">
            FOLLOW OUR SOCIAL MEDIA
          </h1>
          <h2 className="text-2xl mb-4">@P90Futbol</h2>
          <div className="flex justify-center md:justify-start space-x-2">
            <button
              onClick={() =>
                handleLinkClick("https://www.instagram.com/p90futbol/")
              }
              className="bg-red-500 text-white py-1 px-3 rounded-full hover:text-white-400"
            >
              Instagram
            </button>
            <button
              onClick={() =>
                handleLinkClick("https://www.tiktok.com/@p90futbol")
              }
              className="bg-yellow-500 text-white py-1 px-3 rounded-full hover:text-white-400"
            >
              Tiktok
            </button>
            <button
              onClick={() =>
                handleLinkClick(
                  "https://www.google.com/search?q=p90+futbol&oq=P90+Futbol&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGEAyBggCEEUYPDIGCAMQRRg8MgYIBBBFGDzSAQgxNzM5ajBqMagCALACAA&sourceid=chrome&ie=UTF-8"
                )
              }
              className="bg-blue-500 text-white py-1 px-3 rounded-full hover:text-white-400"
            >
              Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaFollow;
