import React, { useState } from "react";
import Footer from "../components/universal/footer";
import { useNavigate } from 'react-router-dom';

const TOSAndPrivacy = () => {
  const [agreement, setAgreement] = useState({
    terms: false,
    media: false,
    liability: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [isOpen, setIsOpen] = useState({
    terms: false,
    media: false,
    liability: false,
  });

  const toggleSection = (section) => {
    setIsOpen((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleCheckboxChange = (e) => {
    setAgreement({ ...agreement, [e.target.name]: e.target.checked });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let valid = true;
    let nameError = "";
    let emailError = "";

    if (!formData.name) {
      nameError = "Name is required.";
      valid = false;
    }

    if (!formData.email) {
      emailError = "Email is required.";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      emailError = "Email address is invalid.";
      valid = false;
    }

    setErrors({
      name: nameError,
      email: emailError,
    });

    return valid;
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate the form before submission
    if (!validateForm()) return;

    // Check if all agreements are checked
    if (!agreement.terms || !agreement.media || !agreement.liability) {
        alert("You must agree to all terms, media release, and liability waiver.");
        return;
    }

    const submissionData = {
        name: formData.name,
        email: formData.email,
        agreement: {
            terms: agreement.terms,
            media: agreement.media,
            liability: agreement.liability,
        },
        timestamp: new Date().toISOString(), // Adding the current timestamp
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/tos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submissionData),
        });

        if (response.ok) {
            // Show success message
            setSuccessMessage("Your agreement has been submitted successfully!");
            // Redirect to /home on success
            navigate('/');
        } else {
            // Handle error, e.g., display an error message
            console.error("Error submitting TOS agreement:", await response.json());
        }
    } catch (error) {
        console.error("Error submitting the form:", error);
    }
};

  

  return (
    <div className="bg-black container mx-auto p-4">
      <div className="bg-black p-6">
        <h1 className="text-2xl font-bold mb-4">By using our service you are agreeing to...</h1>
        {/* <div>
          <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className={`shadow appearance-none border ${errors.name ? "border-red-500" : ""} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
            id="name"
            type="text"
            placeholder="Your Name"
          />
          {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
        </div>
        <br></br>
        <div>
          <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={`shadow appearance-none border ${errors.email ? "border-red-500" : ""} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
            id="email"
            type="email"
            placeholder="Your Email"
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
        </div> */}

        <div className="mt-4">
          <div className="mb-4">
            <h2
              className="text-lg font-bold cursor-pointer"
              onClick={() => toggleSection("terms")}
            >
              {isOpen.terms ? "▼" : "►"} Terms and Conditions
            </h2>
            {isOpen.terms && (
              <div className="pl-4">
                <p className="mb-2">
                  <strong>Payment for Training:</strong> I agree to pay all fees associated with training and activities provided by P90 Futbol Inc. as outlined in the payment schedule provided at the time of registration. Fees are payable in advance and are due on the dates specified in the payment schedule. Failure to pay by the due date may result in my or my child's exclusion from training and activities.
                </p>
                <p className="mb-2">
                  <strong>Commitment to Training:</strong> If you are purchasing any of the following sessions: Small Group Sessions ($33 per session), Partner Group Sessions ($50 per session), Individual Training Sessions ($66 per session), you are committing to an entire semester of training, which will be billed at the end of the month minus the initial session. Semester season’s there are no cancellations; however, reschedules will be grated upon 48 hours in advance.
                </p>
                <p className="mb-2">
                  <strong>Responsibility for Payment:</strong> I understand that I am responsible for any unpaid dues. If payment is not made by the due date, P90 Futbol Inc. reserves the right to suspend or terminate participation in the program until all outstanding amounts are settled. In the event of any unpaid dues, I acknowledge that I may be held liable for any collection costs incurred by P90 Futbol Inc., including legal fees and court costs.
                </p>
                <p className="mb-2">
                  <strong>Late Payments:</strong> Late payments may incur additional fees as specified by P90 Futbol Inc. A late fee of 10% will be applied to any payments received after the due date. Repeated late payments may result in suspension or termination of the participant's enrollment in the program.
                </p>
                <p className="mb-2">
                  <strong>Refund Policy:</strong> Refunds for any fees paid will be at the sole discretion of P90 Futbol Inc. and may be subject to administrative fees. Refund requests must be submitted in writing and will be reviewed on a case-by-case basis. No refunds will be issued for missed sessions, holidays, or participant withdrawal from the program unless otherwise agreed upon in writing by P90 Futbol Inc.
                </p>
                <p className="mb-2">
                  <strong>GST and Other Taxes:</strong> As we are operating in Alberta, a Goods and Services Tax (GST) of 5% will be applied to all fees. I acknowledge that I am responsible for any applicable taxes and that these taxes will be included in the total amount due.
                </p>
                <p className="mb-2">
                  <strong>Security of Payment Information:</strong> P90 Futbol Inc. does not store any credit card information. Payments are processed through Stripe for recurring payments. If there are any issues with payments, please contact admin@p90futbol.com for assistance. All payments are processed through Stripe. P90 Futbol Inc. is not liable for any issues, including but not limited to errors, delays, or disruptions, that may arise from the payment processing services provided by Stripe or any banking institutions involved. P90 Futbol Inc. shall not be held responsible for any unauthorized access, data breaches, or other security issues related to the payment process. Any disputes or issues regarding payments must be resolved directly with Stripe and/or the respective financial institutions.
                </p>
                <p className="mb-2">
                  <strong>Financial Assistance:</strong> P90 Futbol Inc. may offer financial assistance or payment plans on a case-by-case basis. Requests for financial assistance must be submitted in writing and will be reviewed by the administration.
                </p>
                <p className="mb-2">
                  <strong>Changes to Fees and Terms:</strong> P90 Futbol Inc. reserves the right to change the fees, payment schedules, and terms and conditions at any time. Participants will be notified in writing of any changes at least 30 days in advance.
                </p>
                <p className="mb-2">
                  <strong>Agreement to Terms:</strong> By signing at the bottom of this document, I confirm that I have read and understood the payment terms, including all associated policies and fees. I agree to adhere to these terms and conditions throughout my or my child's participation in the program.
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <h2
              className="text-lg font-bold cursor-pointer"
              onClick={() => toggleSection("media")}
            >
              {isOpen.media ? "▼" : "►"} Media Release
            </h2>
            {isOpen.media && (
              <div className="pl-4">
                <p className="mb-2">
                  P90 Futbol Inc. captures photos, videos, and other forms of media during various activities, events, and sessions. These materials may be used for promotional and marketing purposes across different platforms such as social media, flyers, brochures, and our official website. To ensure compliance and to secure permission for the use of such media, we request you to read and sign the following media release and liability waiver form.
                </p>
                <p className="mb-2">
                  <strong>Consent and Release:</strong> I, the undersigned, hereby grant permission to P90 Futbol Inc. to capture and use photos, videos, and other forms of media of the participant named above during various activities and events organized by P90 Futbol Inc. I understand that these materials may be used for promotional and marketing purposes, including but not limited to: Social Media (Facebook, Instagram, Twitter, etc.), Printed materials (flyers, brochures, etc.), Official website of P90 Futbol Inc., Other marketing and promotional materials.
                </p>
                <p className="mb-2">
                  <strong>Media Liability Waiver:</strong> I hereby waive any right to inspect or approve the finished products or the advertising or other copy that may be used in connection with such images, or the use to which they may be applied. I understand that I will not receive any compensation for the use of these materials and that all rights to these media files belong to P90 Futbol Inc. I release, discharge, and agree to hold harmless P90 Futbol Inc., its directors, officers, employees, and agents, from any liability, claims, or demands arising out of or in connection with the use of the media, including but not limited to any claims for defamation or invasion of privacy. I also understand that I have the right to revoke this permission at any time by providing a written notice to P90 Futbol Inc., which will take effect upon receipt.
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <h2
              className="text-lg font-bold cursor-pointer"
              onClick={() => toggleSection("liability")}
            >
              {isOpen.liability ? "▼" : "►"} General Liability
            </h2>
            {isOpen.liability && (
              <div className="pl-4">
                <p className="mb-2">
                  P90 Futbol Inc. is committed to providing a safe and enjoyable experience for all participants. However, participation in soccer activities carries inherent risks of injury. To ensure clarity and mutual understanding, we require all participants (or their parents/guardians if under 18) to read and sign the following liability waiver form.
                </p>
                <p className="mb-2">
                  <strong>Acknowledgment of Risk:</strong> I, the undersigned, acknowledge and fully understand that participation in soccer activities organized by P90 Futbol Inc. involves inherent risks and dangers that could result in serious injury, including permanent disability, paralysis, or death. These risks include, but are not limited to, those caused by terrain, facilities, temperature, weather, condition of participants, equipment, vehicular traffic, actions of other people including, but not limited to, participants, volunteers, spectators, coaches, event officials, and event monitors, and/or producers of the event.
                </p>
                <p className="mb-2">
                  <strong>Release of Liability:</strong> In consideration of being allowed to participate in any way in the P90 Futbol Inc. program, its related events and activities, I hereby agree to release, waive, discharge, and covenant not to sue P90 Futbol Inc., its directors, officers, employees, volunteers, and agents, from any and all liability, claims, demands, actions, and causes of action whatsoever arising out of or related to any loss, damage, or injury, including death, that may be sustained by me, or to any property belonging to me, whether caused by the negligence of the releasees or otherwise, while participating in such activity, or while in, on, or upon the premises where the activity is being conducted.
                </p>
                <p className="mb-2">
                  <strong>Indemnification:</strong> I further agree to indemnify and hold harmless P90 Futbol Inc. from any loss, liability, damage, or costs, including court costs and attorney fees, that they may incur due to my participation in said activities, whether caused by negligence of releasees or otherwise.
                </p>
                <p className="mb-2">
                  <strong>Medical Consent:</strong> In the event of an injury or medical emergency, I authorize P90 Futbol Inc. to seek medical treatment for the participant named above. I understand that P90 Futbol Inc. does not provide medical insurance and that I am responsible for any medical expenses incurred.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-4">
  <h2
    className="text-lg font-bold cursor-pointer"
    onClick={() => toggleSection("privacy")}
  >
    {isOpen.privacy ? "▼" : "►"} Privacy Policy
  </h2>
  {isOpen.privacy && (
    <div className="pl-4">
      <p className="mb-2">
        P90 Futbol Inc. is committed to safeguarding the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our services, including our website, training programs, and other activities.
      </p>
      
      <h3 className="font-bold">Information We Collect:</h3>
      <p className="mb-2">
        We may collect various types of information in connection with the services we provide, including:
        <ul className="list-disc list-inside ml-4">
          <li>Personal Identifiable Information (PII): such as your name, email address, phone number, and billing information.</li>
          <li>Non-Personal Information: including but not limited to IP addresses, browser types, operating systems, and the pages you visit on our website.</li>
          <li>Session Data: information about your interactions with our services, including session durations, click data, and other user activities.</li>
        </ul>
      </p>
      
      <h3 className="font-bold">How We Use Your Information:</h3>
      <p className="mb-2">
        The information we collect is used for various purposes, including:
        <ul className="list-disc list-inside ml-4">
          <li>To provide and maintain our services, ensuring they meet your needs and expectations.</li>
          <li>To communicate with you regarding your account, training sessions, and updates.</li>
          <li>To process transactions securely via Stripe, including payments and refunds.</li>
          <li>To improve and personalize our services, based on user feedback and interactions.</li>
          <li>To comply with legal obligations and protect the rights, property, and safety of P90 Futbol Inc., our users, and the public.</li>
        </ul>
      </p>
      
      <h3 className="font-bold">Sharing Your Information:</h3>
      <p className="mb-2">
        We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:
        <ul className="list-disc list-inside ml-4">
          <li>With service providers who perform functions on our behalf, such as payment processing (e.g., Stripe), website hosting, and email communication.</li>
          <li>To comply with legal obligations, court orders, or governmental requests.</li>
          <li>To protect the rights, property, or safety of P90 Futbol Inc., our users, or the public.</li>
          <li>In connection with a business transaction, such as a merger, acquisition, or asset sale.</li>
        </ul>
      </p>
      
      <h3 className="font-bold">Data Security:</h3>
      <p className="mb-2">
        We take reasonable precautions to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, secure socket layer (SSL) technology, and other security protocols.
      </p>
      <p className="mb-2">
        While we strive to protect your personal information, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.
      </p>
      
      <h3 className="font-bold">Your Rights:</h3>
      <p className="mb-2">
        You have certain rights regarding your personal information, including the right to:
        <ul className="list-disc list-inside ml-4">
          <li>Access, correct, or delete your personal information.</li>
          <li>Opt-out of receiving promotional communications from us.</li>
          <li>Request the restriction of processing your personal information.</li>
          <li>Request the transfer of your personal information to another entity.</li>
        </ul>
      </p>
      <p className="mb-2">
        To exercise these rights, please contact us at admin@p90futbol.com. We will respond to your request within a reasonable timeframe.
      </p>
      
      <h3 className="font-bold">Changes to This Privacy Policy:</h3>
      <p className="mb-2">
        P90 Futbol Inc. reserves the right to update or modify this Privacy Policy at any time. We will notify you of any significant changes by posting the updated policy on our website and indicating the date of the most recent revision.
      </p>
      <p className="mb-2">
        Your continued use of our services after any changes to this Privacy Policy constitutes your acceptance of the revised terms.
      </p>
      
      <h3 className="font-bold">Contact Us:</h3>
      <p className="mb-2">
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at admin@p90futbol.com.
      </p>
    </div>
  )}
</div>


        {/* <div className="mt-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="terms"
              checked={agreement.terms}
              onChange={handleCheckboxChange}
              className="form-checkbox"
            />
            <span className="ml-2">I agree to the Terms and Conditions</span>
          </label>
          <label className="flex items-center mt-2">
            <input
              type="checkbox"
              name="media"
              checked={agreement.media}
              onChange={handleCheckboxChange}
              className="form-checkbox"
            />
            <span className="ml-2">I agree to the Media Release</span>
          </label>
          <label className="flex items-center mt-2">
            <input
              type="checkbox"
              name="liability"
              checked={agreement.liability}
              onChange={handleCheckboxChange}
              className="form-checkbox"
            />
            <span className="ml-2">I agree to the General Liability</span>
          </label>
        </div> */}

        <div className="mt-4">
          {/* <button
            onClick={handleSubmit}
            className={`${
              agreement.terms && agreement.media && agreement.liability ? "bg-blue-500" : "bg-gray-500 cursor-not-allowed"
            } text-white font-bold py-2 px-4 rounded`}
            disabled={!agreement.terms || !agreement.media || !agreement.liability}
          >
            Submit
          </button> */}
          {successMessage && <p className="mt-4 text-green-500">{successMessage}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TOSAndPrivacy;
