import React from 'react';
import './lines.css';

const Lines = () => {
  return (
    <div className="lines-container">
      <img src={require('../../assets/home/left.png')} alt="Left" className="left-image" />
      <img src={require('../../assets/home/right.png')} alt="Right" className="right-image" />
    </div>
  );
};

export default Lines;
