import React from "react";
import PropTypes from "prop-types";

const BannerHeader = ({ text, background }) => {
  return (
    <div
      className="w-full h-500 flex items-center justify-center text-white"
      style={{
        height: "430px",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h1 className="text-4xl lg:text-8xl font-bold">{text}</h1>
    </div>
  );
};

BannerHeader.propTypes = {
  text: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};

export default BannerHeader;
