import React from "react";
import matthewImage from "../../assets/home/matt.png";
import lorenzoImage from "../../assets/home/lorenzo.png";
import aidanImage from "../../assets/home/aidan.png";
import chaseImage from "../../assets/home/chase.png";
import sandipImage from "../../assets/home/sandip.png";
import robertImage from "../../assets/home/robert.png";

const teamData = {
  coaches: [
    {
      name: "MATT MONAGHAN",
      title: "DIRECTOR",
      image: matthewImage,
      bio: "An ex University and College player, Matt Monaghan’s life is football and he has dedicated his whole adult life to fostering proper growth and development of youth Football players through the inception P90 Futbol.  Matt has been coaching club and academy football for 7 years and has been playing competitively all his life.  In University, Matt competed in Usports playing for the University of Lethbridge and competed in CCAA playing for SAIT.  Matt’s passion and drive to get the most out of every player he works with is contagious.",
    },
    {
      name: "LORENZO LANGELI",
      title: "COACH",
      image: lorenzoImage,
      bio: "Lorenzo has honed his skills in diverse and competitive environments across the globe.  He has trained in Minnesota, Madrid, London, and Milan.  Showcasing his adaptability and commitment to the sport.  Currently, Lorenzo is an active player in the Foothills men’s program.  Lorenzo’s approach to soccer is a balanced blend of seriousness and fun, ensuring that his players develop a strong work ethic while also enjoying the game.",
    },
    {
      name: "AIDAN IZADI",
      title: "COACH",
      image: aidanImage,
      bio: "A University and professional player, Aidan Izadi’s career in football includes BK Frem (Danish 3rd Division) ASD San Luca (Italian 4th div Serie D) Vejgaard B (Danish 4th Division) Many players will be sure to follow suit under Aidan’s coaching ability and passion for putting on exciting and energetic sessions.  Aidan is now a player at the University of Calgary and plans to further continue his professional career in the future.",
    },
  ],
  staff: [
    {
      name: "SANDIP MISHRA",
      title: "OPERATIONS & TECHNICAL LEAD",
      image: sandipImage,
      bio: "Growing up and playing in Calgary's youth system, playing for several teams across the city and with a background in Software Engineering, Sandip brings a wealth of experience and valuable operational knowledge to the team. Sandip Founded Vanguard Futbol in 2022, which merged with P90 in 2023 to form a singular organization dedicated to developing the next generation of ballers.",
    },
    {
      name: "ROBERT MACKAY",
      title: "OPERATIONS & MARKETING LEAD",
      image: robertImage,
      bio: "A University and Semi-professional player, Robert Mackay, a previous tier 3 player in u16, has gone from below average youth player to a Usports Captain and Semi-professional player.  His story is an inspiration to all P90 players showing that their dreams are possible.  Behind the scenes, Robert is P90’s chief of operations and director of marketing and development",
    },
  ],
};

const TeamMember = ({ name, title, image, bio }) => (
  <div className="text-center p-4">
    <div className="flex items-center justify-center h-80">
      <img src={image} alt={name} className="h-full object-contain" />
    </div>
    <h3 className="mt-4 text-xl font-bold">{name}</h3>
    <p className="text-sm font-semibold">{title}</p>
    <p className="mt-2 text-sm mx-auto max-w-xs">{bio}</p>
  </div>
);

const OurTeam = () => {
  return (
    <div className="bg-black team-spacing text-white">
      <div className="spacing p-8">
        <h2 className="text-4xl font-bold mb-8 text-center">OUR TEAM</h2>
        <h3 className="text-lg font-bold mb-4 text-center">COACHES</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {teamData.coaches.map((coach, index) => (
            <TeamMember key={index} {...coach} />
          ))}
        </div>
        <h3 className="text-lg font-bold mb-4 text-center">STAFF</h3>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {teamData.staff.map((staff, index) => (
              <TeamMember key={index} {...staff} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
