import React, { useState } from "react";
import "./AvailabilityCalendar.css";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const timeSlots = ["6:30-7:30", "7:30-8:30", "8:30-9:30"]; // Time slots for all days

const AvailabilityCalendar = ({ onAvailabilityChange }) => {
  const [selectedSlots, setSelectedSlots] = useState([]);

  const toggleSlot = (e, day, slot) => {
    e.preventDefault(); // Prevent default behavior

    const slotKey = `${day}-${slot}`;
    let updatedSlots = [...selectedSlots];

    if (updatedSlots.includes(slotKey)) {
      updatedSlots = updatedSlots.filter((s) => s !== slotKey); // Deselect if already selected
    } else if (updatedSlots.length < 100) {
      updatedSlots.push(slotKey); // Add new slot if less than 3 selected
    }

    setSelectedSlots(updatedSlots);

    // Send the updated availability to parent component
    const formattedAvailability = updatedSlots.map((s) => {
      const [day, time] = s.split("-");
      return { day, time }; // time is a string, not an array
    });
    onAvailabilityChange(formattedAvailability);
  };

  return (
    <div className="calendar">
      {daysOfWeek.map((day) => (
        <div key={day} className="calendar-day">
          <h3>{day}</h3>
          <div className="time-slots">
            {timeSlots.map((slot) => (
              <button
                key={`${day}-${slot}`}
                className={`time-slot ${
                  selectedSlots.includes(`${day}-${slot}`) ? "selected" : ""
                }`}
                onClick={(e) => toggleSlot(e, day, slot)}
                disabled={
                  !selectedSlots.includes(`${day}-${slot}`) &&
                  selectedSlots.length >= 18
                }
              >
                {slot}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AvailabilityCalendar;
