import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../assets/home/p90logo.png";

const Navbar = ({ currentMenu }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = [
    { id: 1, label: "HOME", href: "/" },
    { id: 2, label: "ACADEMY", href: "/academy" },
    { id: 5, label: "SHOP", href: "/shop" },
    { id: 6, label: "REGISTER", href: "/membership" },
    { id: 4, label: "CONTACT", href: "/contact" },
  ];

  return (
    <div className="bg-black">
      <div className="spacing spacing-top">
        <nav
          className="bg-black text-white py-4 px-8"
          style={{ fontFamily: "Inter, sans-serif" }}
        >
          <div className="flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-20" />
            </Link>
            <div className="hidden md:flex space-x-6 items-center">
              {menuItems.map((item) => (
                <Link
                  key={item.id}
                  to={item.href}
                  className={`font-extrabold relative ${
                    currentMenu === item.id ? "border-b-2 border-white" : ""
                  } hover:border-b-2 hover:border-white`}
                >
                  {item.label}
                </Link>
              ))}
              <Link to="/schedule" className="bg-white text-black px-4 py-2 font-extrabold ml-4">
                Schedule
              </Link>
            </div>
            <div className="md:hidden flex items-center">
              <button onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
              </button>
            </div>
          </div>
          <div
            className={`md:hidden ${
              isOpen ? "block" : "hidden"
            } transition-all duration-300 ease-in-out`}
          >
            {menuItems.map((item) => (
              <Link
                key={item.id}
                to={item.href}
                className={`block px-4 py-2 font-extrabold relative ${
                  currentMenu === item.id ? "border-b-3 border-white" : ""
                } hover:border-b-3 hover:border-white`}
              >
                {item.label}
              </Link>
            ))}
            <Link to="/Schedule" className="block bg-white text-black px-4 py-2 font-extrabold mx-4 my-2 mt-4">
            Schedule
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
