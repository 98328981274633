import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import BannerHeader from "../components/universal/bannerHeader";
import registerBanner from "../assets/register/register-banner.png";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

// Configure FontAwesome
config.autoAddCss = false;

// Load Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Glofox Iframe component
const GlofoxIframe = () => {
  useEffect(() => {
    // Load the iframe resizer script
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js";
    script.onload = () => {
      // Initialize the iframe resizer
      window.iFrameResize(
        {
          log: false,
          checkOrigin: false,
          tolerance: 10,
          sizeHeight: true,
          heightCalculationMethod: "lowestElement",
          minHeight: 300,
          scrolling: "auto",
        },
        "#glofox_2"
      );
    };
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        id="glofox_2"
        src="https://app.glofox.com/portal/#/branch/66fc5e79664ac97e20076622/memberships?header=memberships"
        width="100%"
        height="0"
        scrolling="no"
        frameBorder="0"
        style={{ display: "block" }} // Ensures the iframe displays as a block element
      />
    </div>
  );
};

const Membership = () => {
  return (
    <div className="bg-black min-h-screen flex flex-col justify-between">
      <div>
        <Banner />
        <Navbar currentMenu={0} />
        <BannerHeader text="REGISTER" background={registerBanner} />
        <br></br>
        <div>
          <div
            style={{
              backgroundColor: "#FF0000", // Red background
              color: "#FFFFFF", // White text
              padding: "10px 20px", // Padding for spacing
              textAlign: "center", // Center the text
              fontWeight: "bold", // Bold text
              fontSize: "15px", // Large font size
              borderRadius: "5px", // Slightly rounded corners
              margin: "10px 0", // Margin for spacing from other elements
            }}
          >
            Please use the promo code: <strong>REGISTRATION</strong> if you're
            signing-up before the season start-date of October 28th to ensure
            you aren't charged prior to the season start.{" "}
          </div>
        </div>

        <br></br>
        <GlofoxIframe />
      </div>
      <Footer />
    </div>
  );
};

export default Membership;
